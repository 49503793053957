.Loading {
    position: absolute;
    display: flex;
    background-color: rgba(255, 255, 255, 0.6);
    /* background-color: transparent; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
}

.Loading .LoadingComponent {
    display: flex;
    margin: auto;
    z-index: 2001;
}