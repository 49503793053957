.dashboard-main {
  display: flex;
  flex-direction: column;

  @media (min-width: 850px) {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
}
.first-half {
  flex: 0 0 30%;
}
.second-half {
  flex: 0 0 70%;
}

.dashboard-inputs {
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 50px;
  @media (max-width: 850px) {
    flex-direction: row;
  }
}

.table-dashboard {
  overflow-x: scroll;
}

.dashboard-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}
.graphics-first-second {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}
