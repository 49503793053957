.login-container {
    height: 100vh;
    background: url("../../assets/empilhadeira.png") no-repeat;
}



.loginA {
    color: rgb(82, 82, 82);
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;

}

.loginA:hover {
    color: #FFB922;
}