@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.Loading {
    position: absolute;
    display: flex;
    background-color: rgba(255, 255, 255, 0.6);
    /* background-color: transparent; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
}

.Loading .LoadingComponent {
    display: flex;
    margin: auto;
    z-index: 2001;
}
.chamados-new-container {
  width: 100%;

  max-width: 1180px;
  margin: 10px auto;
}

.chamados-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .chamados-table-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .chamados-table-header-periodo {
        display: flex;
    }

}
.corrective-wrapper {
  border: 1px solid #7f0b0b;
  padding: 5px;
}

.chamados-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.chamados-table-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .chamados-table-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .chamados-table-header-periodo {
    display: flex;
    flex-direction: column;
  }
  .chamados-table-cards {
    flex-direction: column;
  }
}

.chamados-table-header-periodo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-gap: 8px;
  gap: 8px;
}

.corrective-download-wrapper {
  position: relative;
  cursor: pointer;
}
.corrective-download-count {
  background-color: #7f0b0b;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: -12px;
  right: -10px;
  border-radius: 50%;
}

.chamados-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .chamados-table-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .chamados-table-header-periodo {
        display: flex;
    }

}
.chamados-new-container {
    width: 100%;
    max-width: 1180px;
    margin: 10px auto;

}
.chamados-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.chamados-table-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .chamados-table-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .chamados-table-header-periodo {
    display: flex;
    flex-direction: column;
  }
  .chamados-table-cards {
    flex-direction: column;
  }
}

.chamados-table-header-periodo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-gap: 8px;
  gap: 8px;
}

.corrective-download-wrapper {
  position: relative;
  cursor: pointer;
}
.corrective-download-count {
  background-color: #7f0b0b;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: -12px;
  right: -10px;
  border-radius: 50%;
}

.dashboard-main {
  display: flex;
  flex-direction: column;

  @media (min-width: 850px) {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
  }
}
.first-half {
  flex: 0 0 30%;
}
.second-half {
  flex: 0 0 70%;
}

.dashboard-inputs {
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 50px;
  @media (max-width: 850px) {
    flex-direction: row;
  }
}

.table-dashboard {
  overflow-x: scroll;
}

.dashboard-cards {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}
.graphics-first-second {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}

.loading-container {
    background-color: #F3C843;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.chamados-new-container {
    width: 100%;
    max-width: 1180px;
    margin: 10px auto;

}
.chamados-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .chamados-table-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .chamados-table-header-periodo {
        display: flex;
    }

}
.chamados-new-container {
  width: 100%;
  max-width: 1180px;
  margin: 10px auto;
}

.chamados-new-container {
  width: 100%;
  max-width: 1180px;
  margin: 10px auto;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 2rem;
  padding: 1rem;
}

.dashboard-techs-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
  padding: 0 50px;
}

.dashboard-techs {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  border-left: 6px solid #f3c842;
  cursor: pointer;
}

.dashboard-techs:hover {
  opacity: 0.8;
}

.techs-header {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.dashboard-date-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.selected {
  border-left: 6px solid #003b17;
}

.selected:hover {
  opacity: 0.5;
}

.dashboard-chart-box {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.chart-box-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.dashboard-charts {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  grid-gap: 10px;
  gap: 10px;
  @media screen and (min-width: 768px) {
  }
}

.quantity-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.quantity-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  opacity: 0.03;
  pointer-events: none;
}

.quantity-card.clickable {
  cursor: pointer;
}

.quantity-card.clickable:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.quantity-card.selected {
  opacity: 0.7;
}

.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon i {
  font-size: 1.5rem;
}

.card-content {
  flex: 1 1;
}

.card-value {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.25rem;
}

.card-label {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-cards {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    padding: 0.5rem;
  }

  .quantity-card {
    padding: 1rem;
  }

  .card-icon {
    width: 40px;
    height: 40px;
  }

  .card-value {
    font-size: 1.5rem;
  }
}

.technicians-section {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.techs-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.techs-title {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.techs-title h2 {
  color: #1f2937;
  margin: 0;
  font-size: 1.5rem;
}

.select-all-button {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: transparent;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.select-all-button:hover {
  background: #f9fafb;
  color: #374151;
}

.date-filter-container {
  min-width: 250px;
}

.techs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.tech-card {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
}

.tech-card:hover {
  background: #f3f4f6;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.tech-card.selected {
  background: #f3c84215;
  border-color: #f3c842;
}

.tech-avatar {
  width: 40px;
  height: 40px;
  background: #f3c842;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.tech-info {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tech-name {
  font-weight: 500;
  color: #374151;
}

.tech-check {
  color: #f3c842;
  font-size: 1.125rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .technicians-section {
    padding: 1rem;
    margin: 0.5rem;
  }

  .techs-header {
    flex-direction: column;
    align-items: stretch;
  }

  .techs-title {
    margin-bottom: 1rem;
  }

  .techs-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

/* Date range dropdown customization */
.date-range-dropdown {
  width: 100%;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.date-range-dropdown:focus {
  border-color: #f3c842;
  box-shadow: 0 0 0 2px rgba(243, 200, 66, 0.2);
}

.charts-container {
  padding: 1rem;
  margin-top: 1rem;
  padding-bottom: calc(120px + 1rem);
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.chart-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.chart-header h3 {
  color: #1f2937;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.chart-legend {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.chart-legend i {
  font-size: 1rem;
}

.chart-content {
  height: 400px;
  position: relative;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }

  .chart-card {
    padding: 1rem;
  }

  .chart-content {
    height: 300px;
  }
}

/* Chart customization */
.p-chart {
  width: 100% !important;
  height: 100% !important;
}

/* Add these skeleton styles */
.quantity-card-skeleton {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border-left: 4px solid #e5e7eb;
}

.card-content-skeleton {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.tech-card-skeleton {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.chart-content-skeleton {
  margin-top: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

/* Style PrimeReact's skeleton animation */
.p-skeleton {
  background-color: #f3f4f6 !important;
  -webkit-animation: skeleton-animation 1.5s infinite !important;
          animation: skeleton-animation 1.5s infinite !important;
}

@-webkit-keyframes skeleton-animation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes skeleton-animation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}

.dashboard-disclaimer {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 1rem;
  font-size: 0.875rem;
}

.dashboard-disclaimer i {
  font-size: 1rem;
  color: #856404;
}

@media (max-width: 768px) {
  .dashboard-disclaimer {
    margin: 0.5rem;
    text-align: left;
  }
}

.date-range-text {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.date-range-text i {
  font-size: 0.875rem;
}

.date-filter-container {
  display: flex;
  flex-direction: column;
}

/* Tech selector styles */
.tech-selector {
  margin-top: 1rem;
}

.tech-multiselect {
  width: 100%;
}

.tech-placeholder {
  color: #6b7280;
}

.tech-chips {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.25rem;
}

.tech-chip {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  background: #f3f4f6;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.tech-chip-avatar {
  width: 24px;
  height: 24px;
  background: #f3c842;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.tech-chip-name {
  color: #374151;
}

.tech-chip-remove {
  color: #6b7280;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.tech-chip-remove:hover {
  background: #e5e7eb;
  color: #374151;
}

.tech-item {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 0.5rem;
}

.tech-item-avatar {
  width: 32px;
  height: 32px;
  background: #f3c842;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}

.tech-item-name {
  color: #374151;
  font-size: 0.875rem;
}

/* Override PrimeReact styles */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
  background: #f3f4f6;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #f3c84215;
}

/* Replace the floating filter styles with this updated version */
.floating-filters {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 1200px;
}

.floating-filters-content {
  background: white;
  padding: 0.75rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.floating-date-filter {
  flex: 1 1;
  min-width: 200px;
}

.floating-tech-filter {
  flex: 2 1;
}

.filter-divider {
  width: 1px;
  height: 2rem;
  background: #e5e7eb;
}

/* Update existing date range styles */
.date-range-dropdown {
  width: 100%;
  border-radius: 9999px;
  border: 1px solid #e5e7eb;
}

.date-range-text {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

/* Update MultiSelect styles for consistency */
.tech-multiselect .p-multiselect {
  width: 100%;
  border-radius: 9999px;
  border: 1px solid #e5e7eb;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .floating-filters-content {
    flex-direction: column;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    padding: 0.75rem;
  }

  .floating-date-filter,
  .floating-tech-filter {
    width: 100%;
  }

  .filter-divider {
    width: 100%;
    height: 1px;
    margin: 0.25rem 0;
  }

  .charts-container {
    padding-bottom: calc(180px + 1rem);
  }
}

@media (max-width: 768px) {
  .floating-filters {
    width: calc(100% - 2rem);
    bottom: 0.5rem;
  }

  .charts-container {
    padding-bottom: calc(220px + 1rem);
  }
}

/* Custom date range styles */
.floating-date-filter .custom-date-range {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: #f9fafb;
  border-radius: 8px;
}

/* Ensure dropdowns open upward */
.p-dropdown-panel,
.p-multiselect-panel {
  margin-bottom: 0.5rem !important;
  -webkit-transform-origin: bottom !important;
          transform-origin: bottom !important;
}

/* Add padding to main container to account for floating filters */
.dashboard-container {
  padding-bottom: 7rem;
}

/* Add this with the other filter styles */
.tech-filter-label {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.tech-filter-label i {
  font-size: 0.875rem;
}

/* Update the padding calculation to account for the floating filters */
.charts-container {
  padding: 1rem;
  margin-top: 1rem;
  padding-bottom: calc(120px + 1rem);
}

/* Update responsive padding for different screen sizes */
@media (max-width: 1024px) {
  .charts-container {
    padding-bottom: calc(180px + 1rem);
  }
}

@media (max-width: 768px) {
  .charts-container {
    padding-bottom: calc(220px + 1rem);
  }
}

/* When custom date range is active, add more padding */
.charts-container.with-custom-date {
  padding-bottom: calc(280px + 1rem);
}

/* Add export button styles */
.floating-export {
  display: flex;
  align-items: center;
}

.export-button {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background: transparent !important;
  border-color: #e5e7eb !important;
  color: #6b7280 !important;
}

.export-button:not(:disabled):hover {
  background: #f9fafb !important;
  color: #374151 !important;
  border-color: #d1d5db !important;
}

.export-button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .floating-export {
    width: 100%;
    justify-content: flex-end;
  }
}

/* Add refresh button styles */
.dashboard-refresh {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.refresh-info {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.last-update {
  color: #6b7280;
  font-size: 0.875rem;
}

.refresh-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background: transparent !important;
  border-color: #e5e7eb !important;
  color: #6b7280 !important;
  transition: all 0.2s ease !important;
}

.refresh-button:not(:disabled):hover {
  background: #f9fafb !important;
  color: #374151 !important;
  border-color: #d1d5db !important;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.refresh-button:not(:disabled):active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.refresh-button.p-button-loading {
  background: #f3f4f6 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-refresh {
    padding: 0 0.5rem;
  }

  .refresh-info {
    width: 100%;
    justify-content: space-between;
  }
}

.chamados-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .chamados-table-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .chamados-table-header-periodo {
        display: flex;
    }

}
.login-container {
    height: 100vh;
    background: url(/static/media/empilhadeira.59a27b8f.png) no-repeat;
}



.loginA {
    color: rgb(82, 82, 82);
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;

}

.loginA:hover {
    color: #FFB922;
}
.cadastro-container {
    height: 100vh;
    background: url(/static/media/empilhadeira.59a27b8f.png) no-repeat;
}
.login-container {
    height: 100vh;
    background: url(/static/media/empilhadeira.59a27b8f.png) no-repeat;
}


.login-container {
    height: 100vh;
    background: url(/static/media/empilhadeira.59a27b8f.png) no-repeat;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.MuiListItemText-primary,
.p-badge,
.p-inputtext {
  font-family: "Montserrat", sans-serif !important;
}

body {
  font: 400 12px "Montserrat", sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 16px "Montserrat", sans-serif;
}

button {
  cursor: pointer;
}
form input {
  height: 50px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
}

.input-class {
  height: 50px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
}

.textarea-class {
  width: 100%;
  max-width: 350px;
  min-width: 60px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;

  resize: vertical;
}

form textarea {
  width: 100%;
  max-width: 350px;
  min-width: 60px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;

  resize: vertical;
}

form select {
  width: 100%;
  max-width: 350px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;
}

.button {
  width: 100%;
  height: 46px;
  background-color: #ffb922;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.2s;
}

.alter {
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
  text-decoration: none;
}

.alter:hover {
  color: #ffb922;
}

.bg-color-primary {
  background: #f3c843;
}

.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 60em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}

.MuiAppBar-root {
  z-index: 1000 !important;
}

.map {
  height: 900px;
  width: 100%;
}

.map-marker {
  margin-top: -30px;
}

