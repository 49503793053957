.chamados-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.chamados-table-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .chamados-table-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .chamados-table-header-periodo {
    display: flex;
    flex-direction: column;
  }
  .chamados-table-cards {
    flex-direction: column;
  }
}

.chamados-table-header-periodo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.corrective-download-wrapper {
  position: relative;
  cursor: pointer;
}
.corrective-download-count {
  background-color: #7f0b0b;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: -12px;
  right: -10px;
  border-radius: 50%;
}
