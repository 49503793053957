.loading-container {
    background-color: #F3C843;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
