.chamados-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .chamados-table-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .chamados-table-header-periodo {
        display: flex;
    }

}